/* eslint-disable prettier/prettier */
import { corpCustomerGroupSearch } from "@telia/b2x-rest-client";

export function isOrgNumberSerialCustomerId(scopeCandidate: string): boolean {
  return /^o\d+$/.test(scopeCandidate);
}

export function isEmpty(value: unknown): boolean {
  return value === undefined || value === null;
}

export function scopeIdIsInvalid(scopeId: string | null): boolean {
  return scopeId === null;
}

function hasValue(value: unknown): boolean {
  return !isEmpty(value);
}

function valueOrNull<T>(value: T | undefined): T | null {
  return hasValue(value) ? (value as T) : null;
}

export async function fetchTscidForOrganizationNumber(
  organizationNumber: string
): Promise<string | null> {
  try {
    const response: corpCustomerGroupSearch.GetOrganizationResponse =
      await corpCustomerGroupSearch.PublicSearchControllerService.getOrganizationByOrganizationNumberUsingGet(
        organizationNumber
      );
    const { tscid }: { tscid?: string } = response;
    return valueOrNull<string>(tscid);
  } catch (error) {
    return null;
  }
}

export function removeInitialLetter(value: string): string {
  return value.substring(1);
}
